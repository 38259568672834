import axios from 'axios'

let axiosInstance

export default ({ app, router, store, Vue }) => {
  axiosInstance = axios.create()
  axiosInstance.defaults.baseURL = process.env.API_URL

  axiosInstance.interceptors.request.use(
    (config) => {
      const parseStore = JSON.parse(localStorage.getItem('store'))
      const token = parseStore?.UserStore.loginUserInfo?.JwtToken
      if (token) config.headers.Authorization = `Bearer ${token}` //如果token存在 则添加到请求头中
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

    axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
        (error) => {
      if (error.response?.status === 401) {
        router.push('/')
        return Promise.reject('未登入或權限不足')
      } else if (error.response?.status === 400) {
        return Promise.reject('伺服器無法理解請求')
      } else if (error.response?.status === 420) {
          return Promise.reject(error)
      }
      else if (error.response?.status >= 500) {
        return Promise.reject('伺服器錯誤')
      } else {
        let errorMsg = error.response?.data.RC !== undefined ? error.response.data.RC : error.toString()
        return Promise.reject(errorMsg)
      }
    }
  )

  Vue.prototype.$axios = axiosInstance
}

export { axiosInstance }
