import { axiosInstance as axios } from '../../boot/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    //快客案件追踪列表
    GetQuickCaseTrackingList({ rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/GetQuickCaseTrackingList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //工班订单詳情信息
    GetQuickOrderDTO({ rootGetters }, request) {
      return axios
        .get(`QuickCaseTracking/GetQuickOrderDTO?OrderId=${request.OrderId}`)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //代工申请工班詳情信息
    GetOemOrderApplyAss({ rootGetters }, request) {
      return axios
        .get(`QuickCaseTracking/GetOemOrderApplyAss?OrderId=${request.OrderId}`)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //代工申请工班詳情信息
    GetQuickOemOrderApplyAss({ rootGetters }, request) {
      return axios
        .get(`QuickCaseTracking/GetQuickOemOrderApplyAss?assOrderId=${request.OrderId}`)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //快客代工 编辑 工班项目
    EditApplyAssemblyOrder({ rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/EditApplyAssemblyOrder', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //快客代工 新增 工班项目
    AddApplyAssemblyOrder({ rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/AddApplyAssemblyOrder', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //工班主管列表
    GetAssemblyManagerList({ rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/GetAssemblyManagerList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //添加工班项目
    AddAssemblyOrder({ rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/AddAssemblyOrder', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 工班司机和工班师傅获取列表
    GetAssemblyClerkList({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/GetAssemblyClerkList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    // 工班排程获取API
    GetWorkScheduleList({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/GetWorkScheduleList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GTUTUploadFile({ commit, rootGetters }, request) {
      const formData = new FormData()
      formData.append('File', request.File)
      return axios
        .post(process.env.ASSEMBLY_FILE_URL + '/api/GTUTFile/GTUTUploadFile', formData)
        .then((response) => {
          if (response.data.code === 200) {
            return Promise.resolve(response.data)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetAbnormalAppend({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/SetAbnormalAppend', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetClaimAbnormalInfo({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/GetClaimAbnormalInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //获取代工案件追踪信息
    GetOemCaseTrackingList({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/GetOemCaseTrackingList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //获取代工案件追踪详情信息
    GetOemCaseTrackingDetails({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/GetOemCaseTrackingDetails', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //编辑代工案件追踪信息
    SetOemCaseTrackingInfo({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/SetOemCaseTrackingInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //获取代工挑拣案件信息
    GetOemOrderCaseInfo({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/GetOemOrderCaseInfo', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //代工确认挑拣案件POST
    AddOemOrderCase({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/AddOemOrderCase', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //Mes订单认领异况追加单信息给代工
    SetOemOrderAbnormalAppend({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/SetOemOrderAbnormalAppend', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //根据查询条件获取账号
    GetUserByCondition({ commit, rootGetters }, request) {
      return axios
        .get(`QuickCaseTracking/GetUserByCondition?value=${request.DesignerObj}`)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取申請列表
    GetCapacityReservationList({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/GetCapacityReservationList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取板材列表料號
    GetBigBoardRawBoardSNList({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/GetBigBoardRawBoardSNList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取板材列表顔色
    GetBigBoardColorList({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/GetBigBoardColorList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取五金料號
    GetHardWareProductNumberList({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/GetHardWareProductNumberList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取五金名稱
    GetHardWareMetalNameList({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/GetHardWareMetalNameList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetCapacityReservation({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/SetCapacityReservation', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    CapacityReservationDetails({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/CapacityReservationDetails', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AppointmentExamine({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/AppointmentExamine', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取審核列表
    GetCapacityReservationExamineList({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/GetCapacityReservationExamineList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //獲取審核詳情
    CapacityReservationExamineDetails({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/CapacityReservationExamineDetails', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ExamineCapacityReservation({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/ExamineCapacityReservation', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SetReservationOrderId({ rootGetters }, request) {
      return axios
        .post('CapacityReservation/SetReservationOrderId', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AddAbnormalAppend({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/AddAbnormalAndDesignerModification', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AddAbnormalAppendOem({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/AddAbnormalAndDesignerModificationOem', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    AddOemMesOrder({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/AddOemMesOrder', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetQuickCaseDesignerList({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/GetQuickCaseDesignerList', {
          ...request
        })
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    ApplyMaterialPreparation({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/ApplyMaterialPreparation', {
          ...request
        })
        .then((response) => {
          return Promise.resolve(response.data.Values)
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetMetalCommonSortingList({ commit, rootGetters }, request) {
      return axios
        .post('CapacityReservation/GetMetalCommonSortingList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    NewOrderEstimate({ commit, rootGetters }, request) {
      let data = new FormData()
      request.DesignSubOrderGuids.forEach((it) => {
        data.append('DesignSubOrderGuids', it)
      })
      data.append('ManufacturerID', request.ManufacturerID)
      return axios
        .post('NewOrderEstimate', data)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    QuickApplyCaseClosure({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/QuickApplyCaseClosure', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    OemApplyCaseClosure({ commit, rootGetters }, request) {
      return axios
        .post('QuickCaseTracking/OemApplyCaseClosure', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
