import { axiosInstance as axios } from '../../boot/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    MntSalesmanList({ rootGetters }, request) {
      return axios
        .post('MntSalesmanList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    GetAllSales({ rootGetters }, request) {
      return axios
        .post('GetAllSales', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesTaskAdd({ rootGetters }, request) {
      return axios
        .post('SalesTaskAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesTaskRead({ rootGetters }, request) {
      return axios
        .post('SalesTaskRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesTaskModify({ rootGetters }, request) {
      return axios
        .post('SalesTaskModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -2) {
            return Promise.reject('已有相同名稱的櫃型')
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesTaskDelete({ rootGetters }, request) {
      return axios
        .post('SalesTaskDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //获取客户列表
    GetOrderClientList({ rootGetters }, request) {
      return axios
        .post('OrderClient/GetOrderClientList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //添加客户
    AddOrderClient({ rootGetters }, request) {
      return axios
        .post('OrderClient/AddOrderClient', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //修改客户
    EditOrderClient({ rootGetters }, request) {
      return axios
        .post('OrderClient/EditOrderClient', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    //删除客户
    DeleteOrderClient({ rootGetters }, request) {
      return axios
        .get(`OrderClient/DeleteOrderClient?orderClientId=${request.orderClientId}`)
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else if (response.data.Values.ReqInt === -1) {
            return Promise.reject(response.data.Values.errorInfo)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesTaskList({ rootGetters }, request) {
      return axios
        .post('SalesTaskList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesDairyAdd({ rootGetters }, request) {
      return axios
        .post('SalesDairyAdd', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesDairyRead({ rootGetters }, request) {
      return axios
        .post('SalesDairyRead', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesDairyModify({ rootGetters }, request) {
      return axios
        .post('SalesDairyModify', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesDairyDelete({ rootGetters }, request) {
      return axios
        .post('SalesDairyDelete', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesDairyList({ rootGetters }, request) {
      return axios
        .post('SalesDairyList', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject(response.data.Values.Message)
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesSummary({ rootGetters }, request) {
      return axios
        .post('SalesSummary', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    },
    SalesSummaryDetail({ rootGetters }, request) {
      return axios
        .post('SalesSummaryDetail', {
          ...request
        })
        .then((response) => {
          if (response.data.Values.ReqInt === 0) {
            return Promise.resolve(response.data.Values)
          } else {
            return Promise.reject('發生錯誤')
          }
        })
        .catch((error) => {
          return Promise.reject(error)
        })
    }
  }
}
