import { Notify } from 'quasar'
import { transRoutes } from 'src/router/index'
export default ({ app, router, store, Vue }) => {
  router.beforeEach((to, from, next) => {
    store.dispatch('UserStore/checkLoginUserInfo').then((res) => {
      if (store.getters['UserStore/getUserIsLogin'] === true) {
        if (store.getters['UserStore/getLoginUserIsTermsAgreed'] !== true) {
          if (to.name !== 'terms') {
            return next({
              name: 'terms',
              query: {
                redirect: to.fullPath
              }
            })
          }
        }
      }

      if (to.meta && to.meta.requiredLogin === true) {
        if (store.getters['UserStore/getUserIsLogin'] !== true) {
          Notify.create({
            type: 'negative',
            message: '請先登入'
          })

          next({
            name: 'home',
            query: {
              action: 'login',
              redirect: to.fullPath
            }
          })
          return
        }
        // else if (to.meta.limitAuth) {
        //   for (let i = 0; i < to.meta.limitAuth.length; i++) {
        //     let auth = to.meta.limitAuth[i]

        //     if (store.getters['UserStore/getLoginUserInfo'].UserPermission[auth] !== true) {
        //       Notify.create({
        //         type: 'negative',
        //         message: '帳號權限不足'
        //       })

        //       next({
        //         name: 'home',
        //         query: {
        //           action: 'login',
        //           redirect: to.fullPath
        //         }
        //       })
        //       return
        //     }
        //   }
        // }
      }
      const dynamicRoutes = transRoutes(store.getters['UserStore/getRouterList'])
      let arr1 = [
        {
          Visible: '0',
          path: '/dashboard',
          props: false,
          title: '一级菜单',
          component: () => import('layouts/Backend.vue'),
          icon: null,
          meta: null,
          children: [
            {
              path: '',
              name: 'backend_home',
              component: () => import('pages/Backend/Index.vue')
            },
            {
              path: 'user',
              component: {
                render: (h) => h('router-view')
              },
              children: [
                {
                  path: 'user/backend_user',
                  name: 'backend_user',
                  component: () => import('pages/Backend/User/User.vue'),
                  meta: {
                    requiredLogin: true
                  }
                }
              ]
            }
          ]
        }
      ]
      if (Array.isArray(dynamicRoutes)) {
        dynamicRoutes.forEach((route) => {
          const routeExists = router.getRoutes().some((route) => route.path === '/dashboard')
          if (!routeExists) {
            route.children.push(...arr1[0].children)
            router.addRoute(route)
            if (to.name === 'save-design-order') {
              next({ name: 'save-design-order', query: to.query })
              return
            }
            if (!to.params.pathMatch) {
              next('/dashboard')
            } else {
              next(to.params.pathMatch)
            }
          }
        })
        if (store.getters['UserStore/getUserIsLogin'] === true) {
          if (dynamicRoutes.length === 0) {
            const routeExists = router.getRoutes().some((route) => route.path === '/dashboard')
            if (!routeExists) {
              router.addRoute(arr1[0])
              next(to.params.pathMatch)
            }
          }
        }
      } else {
        console.error('动态路由不是有效的数组')
      }
      next()
    })
  })
}
